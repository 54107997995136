import { useEffect, useState } from 'react';
import {
  Flex,
  View,
  Table,
  TableBody,
  TableHead,
  IconChevronLeft,
  IconChevronRight,
  Grid,
  Button,
} from '@aws-amplify/ui-react';

import { UserItemHeader, UserItem } from './UserItem';

import { configureAmplify, getUserSessionToken } from '../getUserSessionToken';
const Amplify = configureAmplify();

export function UsersList({ loading, setLoading }) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageTokens, setPageTokens] = useState([null]);
  const [users, setUsers] = useState([]);

  useEffect(
    () => {
      let cancel = false;

      setLoading(true);

      getUserList({
        nextToken: pageTokens[currentPageIndex - 1],
      })
        .then(({ userListPage, nextToken }) => {
          if (cancel) return;
          // console.log('getUsers ->  userListPage', userListPage);
          console.log('getUsers ->  nextToken', nextToken);
          setUsers(userListPage);

          console.log('setPageTokens ->  currentPageIndex', currentPageIndex);
          setPageTokens((tokens) => {
            let newTokens = tokens;
            if (currentPageIndex > 0 && !tokens[currentPageIndex]) {
              console.log('addding....', currentPageIndex);
              newTokens = [...tokens, nextToken];
            }
            console.log('setPageTokens ->  newTokens', newTokens);
            return newTokens;
          });
          console.log('getUsers ->  pageTokens', pageTokens);
        })
        .catch((ex) => {
          console.error(ex);
          alert(
            `Oops! something went wrong trying to get the updated users list. Please retry. \n\n Server Response: \n ${ex.message}`
          );
        })
        .finally((_) => {
          setLoading(false);
        });
      //cleanup
      return () => {
        cancel = true;
      };
    },
    // eslint-disable-next-line
    [currentPageIndex]
  );

  return (
    <Flex
      justifyContent="center"
      // alignItems="center"
      direction="column"
      height="80%"
      width="100%"
      padding="50px"
      borderRadius="5px">
      <Paginator />
      <Table
        padding="40px"
        highlightOnHover="true"
        size="small"
        variation="striped"
        height="100%">
        <TableHead>
          <UserItemHeader />
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <UserItem
              key={user.Username}
              user={user}
              onUserChange={onUserChange}
              setLoading={setLoading}
            />
          ))}
        </TableBody>
      </Table>
      <Paginator />
    </Flex>
  );

  function Paginator() {
    return (
      <Grid
        templateColumns="20fr 1fr 1fr"
        direction="row"
        // justifyContent="flex-end"
        fontSize="x-large">
        <View>
          {/* <Text as={'span'}>Index: {currentPageIndex}</Text>
          <Text as={'span'}>
            Token: {pageTokens[currentPageIndex]?.substring(360) || ' NULL'}
          </Text> */}
        </View>
        <Button
          variation="link"
          isDisabled={pageTokens[currentPageIndex] === null}
          isLoading={loading}
          onClick={() => {
            setCurrentPageIndex(currentPageIndex - 1);
          }}>
          <IconChevronLeft /> Prev
        </Button>
        <Button
          variation="link"
          isDisabled={pageTokens[currentPageIndex] === undefined}
          isLoading={loading}
          onClick={() => {
            setCurrentPageIndex(currentPageIndex + 1);
          }}>
          Next <IconChevronRight />
        </Button>
      </Grid>
    );
  }

  async function getUserList({ nextToken }) {
    const { accessToken, idToken } = await getUserSessionToken();
    // const allGroups = ['Admins', 'Users'];
    const getUserListPromises =
      // allGroups.map((group) =>
      [
        Amplify.API.get('AdminQueries', '/listUsers', {
          headers: {
            Authorization: accessToken,
          },
          queryStringParameters: {
            limit: 10,
            token: nextToken, // prevNext === 'next' ? pageToken.next : pageToken.prev,
          },
        }),
        Amplify.API.get('AdminQueries', '/listUsersInGroup', {
          headers: {
            Authorization: accessToken,
          },
          queryStringParameters: {
            groupname: 'Admins',
          },
        }),
      ];
    // console.log('getUserList ->  getUserListPromises', getUserListPromises);
    const [usersList, adminsList] = await Promise.all(getUserListPromises);
    const userListPage = mergeLists(usersList.Users, adminsList.Users);

    return { userListPage, nextToken: usersList.NextToken };

    function mergeLists(usersList = [], adminsList = []) {
      for (let userIndex = 0; userIndex < usersList.length; userIndex++) {
        const user = usersList[userIndex];
        // console.log('mergeLists ->  user', user);
        const adminIndex = adminsList
          .map((u) => u.Username)
          .indexOf(user.Username);
        // console.log('mergeLists ->  adminIndex', adminIndex);
        if (adminIndex > -1) {
          adminsList.splice(adminIndex, 1);
          usersList[userIndex].IsAdmin = true;
        } else {
          usersList[userIndex].IsAdmin = false;
        }
      }
      return usersList;
    }
  }

  function onUserChange(username, applyChange) {
    // console.log('UsersList ->  username', username);
    // console.log('UsersList ->  change', applyChange);
    applyChange({ users });
  }
}
