import './styles.css';
import './webflow.css';
import './normalize.css';
import './nlu-search-ui.webflow.css';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';

import { Login } from './Login/Login';
import { Home } from './Home/Home';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export default function App() {
  const { signOut, user } = useAuthenticator();

  if (user) {
    return <Home signOut={signOut} user={user} />;
  }

  return <Login />;
}
