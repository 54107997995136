import { Amplify, API, Auth } from 'aws-amplify';
import awsExports from './aws-exports';

export function configureAmplify() {
  Amplify.configure(awsExports);

  console.log('configureAmplify ->  Amplify', !!Amplify);
  console.log('configureAmplify ->  API', !!API);
  console.log('configureAmplify ->  Amplify.API', !!Amplify.API);

  return Amplify;
}

export async function getUserSessionToken() {
  const Amplify = configureAmplify();
  const session = await Amplify.Auth.currentSession();
  const accessToken = session.getAccessToken().getJwtToken();
  const idToken = session.getIdToken().getJwtToken();
  return { idToken, accessToken };
}
