import {
  Badge,
  Text,
  TableCell,
  TableRow,
  IconVerified,
  IconMoreVert,
  IconDeleteForever,
  SwitchField,
  Link,
  Menu,
  MenuItem,
} from '@aws-amplify/ui-react';
import moment from 'moment';

import { configureAmplify, getUserSessionToken } from '../getUserSessionToken';
const Amplify = configureAmplify();

export function UserItemHeader() {
  // <TableCell as="th">ID</TableCell>
  return (
    <TableRow>
      <TableCell as="th">Username</TableCell>
      <TableCell as="th">Email Address</TableCell>
      <TableCell as="th">Status</TableCell>
      <TableCell as="th">Admin</TableCell>
      <TableCell as="th">Created</TableCell>
      <TableCell as="th">Enabled</TableCell>
      <TableCell as="th"></TableCell>
    </TableRow>
  );
}

export function UserItem({ user, onUserChange, setLoading }) {
  const userEmail = user.Attributes.find((a) => a.Name === 'email')?.Value;
  const userCreatedAt = user['UserCreateDate'];
  const isUserEmailVerified =
    user.Attributes.find((a) => a.Name === 'email_verified')?.Value === 'true';

  /* <TableCell>{JSON.stringify(user)}</TableCell> */

  return (
    <TableRow key={user.Username}>
      <TableCell>{user.Username}</TableCell>
      <TableCell>
        {userEmail ? (
          <Link isExternal href={`mailto:${userEmail}`}>
            {userEmail}
          </Link>
        ) : (
          ''
        )}
        {isUserEmailVerified ? (
          <Text as="span" fontSize="20px" title="Email address verified! ✔️">
            <IconVerified gap="5px" />
          </Text>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell>
        <Badge
          size="small"
          variation={user.UserStatus === 'CONFIRMED' ? 'success' : 'warning'}>
          {user.UserStatus}
        </Badge>
      </TableCell>
      <TableCell>
        <SwitchField
          size="small"
          defaultChecked={user.IsAdmin}
          gap="5px"
          label={
            <Badge size="small" variation={user.IsAdmin ? 'info' : 'secondary'}>
              {user.IsAdmin ? 'Admin' : 'User'}
            </Badge>
          }
          labelPosition="end"
          onChange={(e) =>
            onUserAdminChange(e, {
              isAdmin: user.IsAdmin,
              username: user.Username,
              userEmail,
            })
          }
        />
      </TableCell>
      <TableCell>{moment(userCreatedAt).fromNow()}</TableCell>
      <TableCell>
        <SwitchField
          size="small"
          defaultChecked={user.Enabled}
          onChange={(e) =>
            onUserEnabledChange(e, {
              isEnabled: user.Enabled,
              username: user.Username,
              userEmail,
            })
          }
          gap="5px"
        />
      </TableCell>
      <TableCell>
        <Menu
          alignItems="flex-start"
          trigger={
            <Text as="span" fontSize="20px">
              <IconMoreVert />
            </Text>
          }>
          <MenuItem
            gap="10px"
            width="100%"
            onClick={(e) =>
              onUserDelete(e, { username: user.Username, userEmail })
            }>
            <IconDeleteForever />
            Delete User
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );

  async function onUserAdminChange(e, { isAdmin, username, userEmail }) {
    try {
      setLoading(true);
      const path = isAdmin ? '/removeUserFromGroup' : '/addUserToGroup';
      const { accessToken, idToken } = await getUserSessionToken();

      const response = await Amplify.API.post('AdminQueries', path, {
        headers: {
          Authorization: accessToken,
        },
        body: {
          username,
          groupname: 'Admins',
        },
      });
      // console.log('onUserAdminChange ->  response', response);
      alert(
        `Successfully changed Admin group membership for ${userEmail} \n\n Server Response: ${response.message}`
      );
      // update local state
      onUserChange(
        username,
        ({ users }) =>
          (users.find((u) => u.Username === username).IsAdmin = !isAdmin)
      );
    } catch (ex) {
      console.error(ex);
      alert(
        `Oops! Changing the Admin group membership failed for ${userEmail} \n\n Please reload the page and retry!`
      );
    } finally {
      setLoading(false);
    }
  }

  async function onUserEnabledChange(e, { isEnabled, username, userEmail }) {
    if (
      isEnabled &&
      !window.confirm(
        `Are you sure you want to disable the user: ${userEmail} ?`
      )
    ) {
      return false;
    }

    try {
      setLoading(true);
      const path = isEnabled ? '/disableUser' : '/enableUser';
      const { accessToken, idToken } = await getUserSessionToken();

      const response = await Amplify.API.post('AdminQueries', path, {
        headers: {
          Authorization: accessToken,
        },
        body: {
          username,
        },
      });
      // console.log('onUserEnabledChange ->  response', response);
      alert(
        `Successfully updated the Enabled status for ${userEmail} \n\n Server Response: ${response.message}`
      );
      // update local state
      onUserChange(
        username,
        ({ users }) =>
          (users.find((u) => u.Username === username).Enabled = !isEnabled)
      );
    } catch (ex) {
      console.error(ex);
      alert(
        `Oops! Changing the Enabled status failed for ${userEmail} \n\n Please reload the page and retry!`
      );
    } finally {
      setLoading(false);
    }
  }

  async function onUserDelete(e, { username, userEmail }) {
    if (
      !window.confirm(
        `Are you sure you want to delete the user: ${userEmail} ? \n Q  WARNING: There is NO recovery for this deletion`
      )
    ) {
      return false;
    }

    try {
      setLoading(true);
      const path = '/deleteUser';
      const { accessToken, idToken } = await getUserSessionToken();

      const response = await Amplify.API.post('AdminQueries', path, {
        headers: {
          Authorization: accessToken,
        },
        body: {
          username,
        },
      });
      console.log('onUserDelete ->  response', response);

      alert(
        `Successfully deleted the user with email: ${userEmail} \n\n Server Response: ${response.message}`
      );

      // update local state
      onUserChange(username, ({ users }) => {
        const userIndex = users.map((u) => u.Username).indexOf(username);
        userIndex > -1 && users.splice(userIndex, 1);
      });
    } catch (ex) {
      console.error(ex);
      alert(
        `Oops! Deletion failed for ${userEmail} \n\n Please reload the page and retry!`
      );
    } finally {
      setLoading(false);
    }
  }
}
