import { Flex, Text, useTheme } from '@aws-amplify/ui-react';

export function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text fontSize={'small'}>Copyright © 2021 Function Factories</Text>
    </Flex>
  );
}
