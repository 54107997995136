import { Authenticator, Flex, useTheme, Grid, Image, View } from '@aws-amplify/ui-react';

import { Header } from './Header';
import { Footer } from './Footer';
import { SignInHeader } from './SignInHeader';
import { SignInFooter } from './SignInFooter';
import { Home } from '../Home/Home';

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  Footer,
};

export function Login() {
  const { tokens } = useTheme();

  return (
    <Grid templateColumns={{ base: "1fr 0", medium: "1.5fr 1fr" }}>
      <Flex
        backgroundColor={tokens.colors.background.secondary}
        justifyContent="center"
      >
        <Authenticator components={components}>
        {({ signOut, user }) => {
           return <Home signOut={signOut} user={user} />;
         }}
        </Authenticator>
      </Flex>
      <View height="100vh">
        <Image
          src="https://s3.amazonaws.com/functionfactories.com/wp1892769-factory-wallpapers.jpg"
          width="100%"
          height="100%"
          objectFit="cover"
        />
      </View>
    </Grid>
  );
}
