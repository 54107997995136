import { useState } from 'react';
import {
  Button,
  Flex,
  IconAdd,
  TextField,
  PasswordField,
  FieldGroupIcon,
  IconEmail,
  IconLock,
  Alert,
  ButtonGroup,
  SwitchField,
} from '@aws-amplify/ui-react';

import { configureAmplify, getUserSessionToken } from '../getUserSessionToken';

export function CreateUser({ onNewUserCreated }) {
  const initialNewUserState = {
    email: '',
    password: '',
    emailVerified: false,
    sendInvite: true,
  };
  const [newUserState, setNewUserState] = useState(initialNewUserState);
  const [isCreatingUser, setIsCreatingUser] = useState(false);

  return (
    <>
      <Button
        variation="secondary"
        type="button"
        size="small"
        height="2rem"
        alignSelf="flex-start"
        onClick={() => setIsCreatingUser(true)}>
        <IconAdd /> Add New User
      </Button>

      <Flex
        as="form"
        direction="row"
        width="30rem"
        gap="5px"
        padding="10px"
        border="black"
        display={isCreatingUser ? 'block' : 'none'}
        onSubmit={(e) => onAddNewUser(e)}>
        {/* New User Email */}
        <TextField
          id="newUserEmail"
          type="email"
          size="small"
          label="Email"
          placeholder="user@corp.com"
          // descriptiveText="Please provide email for new user"
          errorMessage="Please enter valid email address"
          isRequired={true}
          innerStartComponent={
            <FieldGroupIcon>
              <IconEmail />
            </FieldGroupIcon>
          }
          onChange={(e) =>
            setNewUserState((s) => ({ ...s, email: e.target.value }))
          }
          value={newUserState.email}
        />

        <Flex direction="row">
          <SwitchField
            defaultChecked={false}
            fontSize="x-small"
            padding="5px 2px"
            labelPosition="end"
            label="Mark email as verified"
            title="Leave unchecked to have the user verify their email (for account recovery)"
            onChange={(e) =>
              setNewUserState((s) => ({
                ...s,
                emailVerified: e.target.checked,
              }))
            }
          />
          <SwitchField
            defaultChecked={true}
            fontSize="x-small"
            padding="5px 2px"
            labelPosition="end"
            label="Send user an invite with temporary password"
            title="Leave unchecked if you do not want to send an invitation email"
            onChange={(e) =>
              setNewUserState((s) => ({
                ...s,
                sendInvite: e.target.checked,
              }))
            }
          />
        </Flex>

        <PasswordField
          id="newUserPassword"
          size="small"
          label={`Temporary Password : ${
            newUserState.sendInvite ? '(optional)' : '(required)'
          }`}
          placeholder="Skip to auto-generate. Required when no email invite."
          title="Set an inital password, skip to auto-generate. Required when no email invite."
          // descriptiveText="Please provide password for new user"
          errorMessage="Please enter valid password"
          isRequired={!newUserState.sendInvite}
          hideShowPassword={false}
          autoComplete="new-password"
          innerStartComponent={
            <FieldGroupIcon>
              <IconLock />
            </FieldGroupIcon>
          }
          onChange={(e) =>
            setNewUserState((s) => ({ ...s, password: e.target.value }))
          }
          value={newUserState.password}
        />
        <ButtonGroup padding="10px">
          <Button
            variation="secondary"
            type="button"
            size="small"
            height="2rem"
            isFullWidth={true}
            onClick={() => {
              setIsCreatingUser(false);
              setNewUserState(initialNewUserState);
            }}>
            Cancel
          </Button>
          <Button
            variation="primary"
            type="submit"
            size="small"
            height="2rem"
            isFullWidth={true}>
            Create User
          </Button>
        </ButtonGroup>
        {newUserState.message && (
          <Alert
            variation={newUserState.error ? 'warning' : 'success'}
            isDismissible={true}
            hasIcon={true}
            heading="Create New User">
            {newUserState.message}
          </Alert>
        )}
      </Flex>
    </>
  );

  async function onAddNewUser(e) {
    e.preventDefault();
    // alert(
    //   `creating new user: UN: ${newUserState.email} | PW: ${newUserState.password}`
    // );
    const { email, password, emailVerified, sendInvite } = newUserState;
    try {
      const Amplify = configureAmplify();
      const { accessToken, idToken } = await getUserSessionToken();
      const newUser = await Amplify.API.post('AdminQueries', '/createUser', {
        headers: {
          Authorization: accessToken,
        },
        body: {
          username: email,
          password,
          emailVerified,
          sendInvite,
        },
      });

      // const newUser = await Amplify.Auth.signUp({
      //   username: email,
      //   password: newUserState.password,
      //   userAttributes: {
      //     email,
      //   },
      // });
      console.log('onAddNewUser ->  newUser', newUser);
      const newUserRecord = {
        ...newUser,
        IsAdmin: false,
      };
      onNewUserCreated({ newUserRecord });
      setNewUserState({
        ...initialNewUserState,
        message: newUser.message, //: 'Successfully created user!',
        error: false,
      });
      // auto-close success dialog + form after delay
      // setTimeout(() => {
      //   setIsCreatingUser(false);
      //   setNewUserState(initialNewUserState);
      // }, 5000);
      return newUser;
    } catch (ex) {
      const errorMessage = ex.response.data.message;
      console.log('onAddNewUser ->  ex', errorMessage);
      setNewUserState((s) => ({
        ...s,
        message: errorMessage,
        error: true,
      }));
      // alert(
      //   `Oops! Something went wrong attempting to create new user. Please review the server response below - \n\n ${errorMessage}`
      // );
    }
  }
}
