import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { ErrorBoundary } from './ErrorBoundary';
import App from './App';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <AmplifyProvider>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </AmplifyProvider>
    </ErrorBoundary>
  </StrictMode>,
  rootElement
);
