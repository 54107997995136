import { Divider, Flex, Heading } from '@aws-amplify/ui-react';

import { UsersList } from './UsersList';
import { CreateUser } from './CreateUser';

export function AdminPage({ loading, setLoading }) {
  return (
    <>
      <Heading level={5} padding="5px" justifyContent="center">
        User Administration
      </Heading>
      <Divider />

      <Flex
        justifyContent="flex-start"
        alignItems="center"
        direction="column"
        gap="5px"
        padding="10px">
        {/* Create User form */}
        <CreateUser onNewUserCreated={onNewUserCreated} />
        {/* Users listing grid */}
        <UsersList loading={loading} setLoading={setLoading} />
      </Flex>
    </>
  );

  async function onNewUserCreated({ newUserRecord }) {
    // users.push(newUserRecord);
  }
}
