import { Flex, Link, useAuthenticator, useTheme } from '@aws-amplify/ui-react';

export function SignInFooter() {
  const { toSignIn, toResetPassword, route } = useAuthenticator();
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
      <Link onClick={toResetPassword}>Forgot your password? Reset it here</Link>
      {route !== 'signIn' && <Link onClick={toSignIn}>Back to Sign In</Link>}
    </Flex>
  );
}
